/**
 * Componente de cambio de contraseña: Muestra el formulario de cambio de contraseña
 */

import { Box, Button, Description, Heading, Input, Label, Stack, StackRow } from "codekit";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { useRestorePasswordMutation, useChangePasswordMutation } from "../../libs/redux/slices/forgotSlice/forgotApiSlice";
import { getPasswordStrength, setPasswordStrength } from "../../libs/redux/slices/passwordSlice/passwordSlice";
import {
  getUser as getUserForgot,
  showSuccessMessage as showSuccessMessageForgot,
  reset,
} from "../../libs/redux/slices/forgotSlice/forgotSlice";
import {
  getUser as getUserAuth,
  getPasswordFormMessage,
  showSuccessMessage as showSuccessMessageAuth,
  signOut,
} from "../../libs/redux/slices/authSlice/authSlice";

const Updpwdxx = ({ origin }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const isLoginRequest = origin === "LOGIN-PAGE";
  const isForgotPasswordRequest = origin === "FORGOT-PAGE";

  const usridxxx = useSelector(isLoginRequest ? getUserAuth : getUserForgot);

  const passwordStrength = useSelector(getPasswordStrength);
  const passwordFormMessage = useSelector(getPasswordFormMessage);

  const { isPasswordStrong } = passwordStrength;

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm();

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const dispatch = useDispatch();

  const onSubmit = async (formData) => {
    const { password, old_password } = formData;

    try {
      await changePassword({ usridxxx, old_password, password }).unwrap();

      if (isLoginRequest) {
        dispatch(signOut());
        dispatch(showSuccessMessageAuth(true));
      } else {
        dispatch(reset());
        dispatch(showSuccessMessageForgot(true));
      }

    } catch (error) { }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handlePasswordConfirmChange = (e) => {
    const passwordConfirm = e.target.value;
    setPasswordConfirm(passwordConfirm);
  };

  useEffect(() => {
    dispatch(setPasswordStrength(password));
  }, [dispatch, password]);

  const arePasswordEqual = password === passwordConfirm;
  const isFormValid = isValid && arePasswordEqual && isPasswordStrong;

  return (
    <>
      <Box textAlign="center" mb={8}>
        <Stack gap={8}>
          <Heading variant="heading-6">Actualizar contraseña</Heading>
          <Description variant="description-2">
            Porfavor ingrese nueva contraseña. Recuerde que esta debe tener una longitud mayor o igual a 8 caracteres, incluir números,
            combinacion de letras mayúsculas y minúsculas, caracteres especiales y no debe terne espacios en blanco. Haga clic en "Siguiente" cuando finalice
          </Description>
        </Stack>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={8}>
          <StackRow>
            <Label htmlFor="password">Contraseña Actual</Label>
            <Input
              id="old_password"
              type="password"
              disabled={isLoading}
              {...register("old_password", { required: true })}
              isInvalid={errors.password}
              style={{ textTransform: "none" }}
            />
          </StackRow>
          <StackRow>
            <Label htmlFor="password">Nueva Contraseña</Label>
            <Input
              id="password"
              type="password"
              disabled={isLoading}
              {...register("password", { required: true })}
              value={password}
              onChange={handlePasswordChange}
              isInvalid={errors.password}
              style={{ textTransform: "none" }}
            />
          </StackRow>
          <StackRow>
            <Label htmlFor="passwordConfirm">Confirmar contraseña</Label>
            <Input
              id="passwordConfirm"
              type="password"
              disabled={isLoading}
              {...register("passwordConfirm", { required: true })}
              value={passwordConfirm}
              onChange={handlePasswordConfirmChange}
              isInvalid={errors.passwordConfirm}
              style={{ textTransform: "none" }}
            />
          </StackRow>

          <Button  type="submit" variant="primary-bold" isLoading={isLoading} isFull>
            Siguiente
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default Updpwdxx;
