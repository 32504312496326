/**
 * Componente de confirmacion de codigo de seguridad: Muestra el formulario del codigo de seguridad para su confirmacion
 */

import { Body, Box, Button, Description, Heading, Input, Label, Stack, StackRow } from "codekit";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useLoginRestoreCodeMutation, useSendEmailMutation, useSendTokenCodeMutation } from "../../libs/redux/slices/forgotSlice/forgotApiSlice";
import { getUser as getUserForgot, confirmCode } from "../../libs/redux/slices/forgotSlice/forgotSlice";
import { useLoginTokenMutation } from "../../libs/redux/slices/authSlice/authApiSlice";
import { getUser as getUserAuth, signToken } from "../../libs/redux/slices/authSlice/authSlice";

const Codeconf = ({ origin }) => {
  const isLoginRequest = origin === "LOGIN-PAGE";
  const isForgotPasswordRequest = origin === "FORGOT-PAGE";

  const usridxxx = useSelector(isLoginRequest ? getUserAuth : getUserForgot);

  const [number1, setNumber1] = useState("");
  const [number2, setNumber2] = useState("");
  const [number3, setNumber3] = useState("");
  const [number4, setNumber4] = useState("");

  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(30);

  const [remainingTimeLabel, setRemainingTimeLabel] = useState("0:30");
  const [isResendEnabled, setIsResendEnabled] = useState(false);

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
  });

  const [loginToken, { isLoading: isLoadingLoginToken }] = useLoginTokenMutation();
  const [loginRestoreCode, { isLoading: isLoadingLoginRestoreCode }] = useLoginRestoreCodeMutation();
  const [sendEmail, { isLoading: isLoadingSendEmail }] = useSendEmailMutation();
  const [sendTokenCode, { isLoading: isLoadingsendTokenCode }] = useSendTokenCodeMutation();


  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onResend = async () => {
    if (isLoginRequest) {
      await sendTokenCode({ email: usridxxx }).unwrap();
      setIsResendEnabled(false);
      setRemainingMinutes(0);
      setRemainingSeconds(30);
      setRemainingTimeLabel("0:30");
    }

    if (isForgotPasswordRequest) {
      try {
        await sendEmail({ email: usridxxx }).unwrap();
        setIsResendEnabled(false);
        setRemainingMinutes(0);
        setRemainingSeconds(30);
        setRemainingTimeLabel("0:30");
      } catch (error) {}
    }
  };

  const onSubmit = async (formData) => {
    try {
      const { number1, number2, number3, number4 } = formData;

      const userCode = `${number1}${number2}${number3}${number4}`;

      if (isLoginRequest) {
        const userData = await loginToken({ usridxxx, userCode }).unwrap();
        dispatch(signToken({ usridxxx, userData }));
      }

      if (isForgotPasswordRequest) {
        const userData = await loginRestoreCode({ usridxxx, userCode }).unwrap();
        dispatch(confirmCode({ usridxxx, userData }));
      }
    } catch (error) {}
  };

  const handleNumberInput = (e) => {
    const target = e.target;
    const { id, value } = target;
    const firstDigit = value.slice(0, 1);

    if (id === "number1") {
      setNumber1(firstDigit);
    } else if (id === "number2") {
      setNumber2(firstDigit);
    } else if (id === "number3") {
      setNumber3(firstDigit);
    } else if (id === "number4") {
      setNumber4(firstDigit);
    }
  };

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData.getData("text/plain");

    if (clipboardData.length === 4 && !isNaN(clipboardData)) {
      setNumber1(clipboardData.charAt(0));
      setNumber2(clipboardData.charAt(1));
      setNumber3(clipboardData.charAt(2));
      setNumber4(clipboardData.charAt(3));
    }
  };

  const isLoading = isLoginRequest ? isLoadingLoginToken : isLoadingLoginRestoreCode;

  useEffect(() => {
    if (number1.length === 1) {
      setFocus("number2");
    }
    if (number2.length === 1) {
      setFocus("number3");
    }
    if (number3.length === 1) {
      setFocus("number4");
    }
  }, [number1, number2, number3, number4, setFocus]);

  /**
   * Hook que muestra el temporizador para reenviar el codigo de confirmacion
   */

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if(remainingSeconds == 0 && remainingMinutes == 0) {
        setIsResendEnabled(true);
      }

      if (remainingSeconds > 0) {
        setRemainingSeconds(remainingSeconds => remainingSeconds - 1);
      } else {
        if (remainingMinutes > 0) {
          setRemainingMinutes(remainingMinutes => remainingMinutes - 1);
          setRemainingSeconds(59);
        } else {
          clearInterval(timerInterval);
        }
      }

      const format = `${remainingMinutes}:${remainingSeconds.toString().padStart(2, "0")}`;
      setRemainingTimeLabel(format);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [remainingMinutes, remainingSeconds]);

  return (
    <Box>
      <Box textAlign="center" mb={8}>
        <Stack gap={8}>
          <Heading variant="heading-6">¡Estamos aquí para ayudarte!</Heading>
          <Body variant="body-2">
            Te hemos enviado un correo electrónico con un código de seguridad. Ingresalo aquí para continuar.
          </Body>
        </Stack>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={8}>
          <StackRow>
            <Stack gap={4}>
              <Stack gap={4} columns={4}>
                <Input
                  type="number"
                  disabled={isLoading}
                  isInvalid={errors.number1}
                  placeholder="0"
                  maxLength={1}
                  {...register("number1", { required: true })}
                  id="number1"
                  value={number1}
                  onChange={handleNumberInput}
                  onPaste={handlePaste}
                  isOtp
                />
                <Input
                  type="number"
                  disabled={isLoading}
                  isInvalid={errors.number2}
                  placeholder="0"
                  maxLength={1}
                  {...register("number2", { required: true })}
                  id="number2"
                  value={number2}
                  onChange={handleNumberInput}
                  onPaste={handlePaste}
                  isOtp
                />
                <Input
                  type="number"
                  disabled={isLoading}
                  isInvalid={errors.number3}
                  placeholder="0"
                  maxLength={1}
                  {...register("number3", { required: true })}
                  id="number3"
                  value={number3}
                  onChange={handleNumberInput}
                  onPaste={handlePaste}
                  isOtp
                />
                <Input
                  type="number"
                  disabled={isLoading}
                  isInvalid={errors.number4}
                  placeholder="0"
                  maxLength={1}
                  {...register("number4", { required: true })}
                  id="number4"
                  value={number4}
                  onChange={handleNumberInput}
                  onPaste={handlePaste}
                  isOtp
                />
              </Stack>

              {!isResendEnabled ? 
                <Description variant="description-3" alignment="center" color="warning-500">
                  Podras generar otro codigo dentro de: {remainingTimeLabel}
                </Description>
                : ""
              }
            </Stack>
          </StackRow>

          {isResendEnabled && (
            <Button type="button" variant="primary" disabled={isLoading} isLoading={isLoadingsendTokenCode} onClick={onResend} isFull>
              {isLoadingsendTokenCode ? "Cargando" : "Volver a enviar"}
            </Button>
          )}

          <Button type="submit"  variant="primary-bold" isLoading={isLoading} isFull>
            Enviar
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default Codeconf;
